@use 'utils' as *;

.container {
  align-self: center;
  flex-basis: 470px;
  @include gap(24px);
  margin: 0 auto;
}

.header {
  @include gap(24px);
  text-align: center;
}
