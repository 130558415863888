@use 'utils' as *;

.activities {
  max-width: 570px;
  margin: 0 auto;
  @include gap(16px);
}

.activity {
  @include gap(16px);
  padding: 24px 40px;
  text-align: center;
  background-color: #f6f6f6;
  border-radius: 24px;
  border: 2px solid transparent;

  &.active {
    background-color: rgba(#00ffc4, 0.04);
    border-color: #00ffc4;
  }

  &.complete {
    background-color: transparent;
    border-color: #cfcfcf;
  }
}

.header {
  @include gap(8px);
}

.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.subheading {
  font-size: 16px;
  line-height: 22px;
}

.reward {
  @include gap(8px, right);
  @include flexCenter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  > span {
    &:first-of-type {
      color: #00b387;
    }

    &:nth-of-type(2) {
      @include gap(6px, right);
      display: flex;
    }
  }
}

.loader {
  position: relative;
  padding-top: 50px;
}
