@use 'utils' as *;

.form {
  display: grid;
  grid-gap: 32px;
  width: 100%;
}

.inputs {
  display: grid;
  grid-gap: 8px;
}

.arrow {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  color: #00ffc4;
}

.bottom {
  @include gap(10px);

  p {
    text-align: center;
    color: #FF0101;
  }
}

.submit {
  width: 100%;
}

.loading {
  position: relative;
  padding-top: 50px;
}
