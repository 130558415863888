@use 'utils' as *;

.wallet {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #000;
}

.icon {
  margin-right: 8px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ebeeee;
}
