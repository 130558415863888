@use '../../../utils/index' as *;

.wrapper {
  display: flex;
  align-items: center;
  @include gap(10px, right);

  svg {
    width: 20px;
    height: 20px;
  }
}

.balance {
  display: block;
  line-height: 1;

  > * + * {
    margin-left: 4px;
  }
}

.amount {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.unit {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  color: rgba(#000, 0.6);
}
