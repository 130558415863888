.modal {
  width: calc(100% - 16px);
  max-width: 400px;
  padding: 0;
  border-radius: 4px;
  background-color: #f6f8f8;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
  }
}

.wrapper {
  padding: 30px 32px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #222424;
}

.modal-close {
  position: relative;
  bottom: 2px;
  left: 5px;
  margin-left: auto;
  transition: color 350ms ease;

  &:hover {
    color: #777777;
  }
}
