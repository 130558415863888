.xs {
  font-size: 12px;
  line-height: 16px;
}

.sm {
  font-size: 14px;
  line-height: 20px;
}

.md {
  font-size: 16px;
  line-height: 23px;
}

.lg {
  font-size: 18px;
  line-height: 25px;
}

.xl {
  font-size: 20px;
  line-height: 28px;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}
