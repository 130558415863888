@use '@gear-js/ui/resets';

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 100;
  src: url('../fonts/Anuphan-Thin.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 200;
  src: url('../fonts/Anuphan-ExtraLight.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 300;
  src: url('../fonts/Anuphan-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 400;
  src: url('../fonts/Anuphan-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 500;
  src: url('../fonts/Anuphan-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 600;
  src: url('../fonts/Anuphan-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan-Fallback';
  font-weight: 700;
  src: url('../fonts/Anuphan-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Anuphan';
  src: url('../fonts/anuphan-variable.woff2') format('woff2-variations');
  font-weight: 100 700;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

*,
::after,
::before {
  border: none;
  font-family: inherit;
}

body {
  font-family: 'Anuphan', sans-serif;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  font-variant-numeric: lining-nums proportional-nums;

  @supports not (font-variation-settings: "wdth" 115) {
    & {
      font-family: 'Anuphan-Fallback', sans-serif;
    }
  }

  &.modal-open {
    overflow-y: hidden;
  }
}

button {
  cursor: pointer;
  font-family: inherit;

  &:disabled {
    pointer-events: none;
  }
}
