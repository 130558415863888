.xs {
  font-size: 24px;
  line-height: 38px;
}

.sm {
  font-size: 32px;
  line-height: 42px;
}

.md {
  font-size: 40px;
  line-height: 56px;
}

.lg {
  font-size: 64px;
  line-height: 72px;
}

.xl {
  font-size: 72px;
  line-height: 86px;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}
