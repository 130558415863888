@use 'utils' as *;

.form {
  @include gap(24px);

  .btn {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    color: #000000;
    background: linear-gradient(0deg, #00ffc4, #00ffc4), linear-gradient(270deg, #cfcfcf 0%, #cfcfcf 100%);
    border-radius: 2px;
  }

  .button {
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    background: #ffffff;
    border: 2px solid #000000;
  }

  .current {
    width: 100%;
    padding: 14px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    background: #f1f1f1;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }
  }
}

.textInput {
  position: relative;

  input[type='text'] {
    border-radius: 0.125rem;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
    height: 60px;
    background: #ffffff;
    padding-right: 0.875rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.875rem;
    font-size: 16px;
    line-height: 26px;
    line-height: 1.5rem;

    &:focus {
      border-color: #00ffc4;
      outline: none;
    }

    &[data-invalid="true"] {
      border-color: #fc174d;
    }
  }

  label {
    position: absolute;
    background: #fff;
    color: #313635;
    font-size: 12px;
    padding: 0 5px;
    z-index: 1;
    top: -9px;
    left: 0;
    margin-left: 0.875rem;

    &:focus {
      border-color: #00ffc4;
      outline: none;
    }
  }
}

.link {
  text-decoration: underline;
}
