@use 'utils' as *;

.footer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 20px 0;
  color: rgba(#000, 0.7);
}

.links {
  @include gap(16px, right);
  font-weight: 600;
}
