@use '@gear-js/ui/mixins' as *;
@use 'utils' as *;

.wallet {
  @include gap(20px, right);
  display: flex;
  align-items: center;
}

.button {
  font-size: 16px;
  padding-right: 24px;
  padding-left: 24px;
  text-transform: capitalize;

  span {
    @include textOverflow;
    display: block;
    max-width: 15ch;
    white-space: nowrap;
  }

  svg {
    cursor: pointer;
  }
}
