@use 'utils' as *;

.container {
  @include gap(40px);
  margin: 0 auto;
}

.header {
  @include gap(24px);
  text-align: center;
}
