@use 'utils' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.menu {
  margin: 0 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.03em;
  white-space: nowrap;

  > ul {
    display: flex;
    @include gap(24px, right);
  }

  .link {
    padding: 6px 0;

    &:global(.active) {
      border-bottom: 2px solid #00ffc4;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.25;
}

.user {
  @include gap(40px, right);
  display: flex;
  align-items: center;

  .points {
    @include gap(4px, right);
  }
}

.balance {
  b {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.4px;
  }
}
