@use 'utils' as *;

.wrapper {
  position: relative;
}

.input {
  padding-top: 13px;
  padding-bottom: 13px;
  white-space: nowrap;

  input {
    font-size: 24px;
  }
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 2px;
  font-size: 12px;
  color: #FC174D;
}

.coin {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > * + * {
    margin-top: 1px;
  }
}

.coinType {
  @include gap(8px, right);

  svg {
    width: 24px;
    height: 24px;
  }

  b {
    font-weight: 600;
  }
}

.coinValue {
  @include gap(8px, right);
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  line-height: 17px;

  b {
    font-weight: 700;
  }
}

.maxButton {
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  letter-spacing: 0;
  color: #00b387;

  &:hover {
    color: #0ed3a3;
  }
}
