@use 'utils' as *;

.container {
  flex: 1;
  display: grid;
  @include gap(60px);
  width: 100%;
}

.content {
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 48px;
  grid-template-rows: 1fr min-content min-content;
  height: 100%;
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 500px;

  picture {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: visible;
  }
}

.header {
  @include gap(24px);
  text-align: center;
}
