@use 'utils' as *;

.leaderboard {
  //min-height: 670px;
  @include loaderWrapper;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .heading {
    font-size: 20px;
    font-weight: 500;
  }

  .input {
    max-width: 300px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: #202020;
  white-space: nowrap;

  thead {
    color: #fff;
    background-color: #000;

    th {
      padding: 10px;
      font-weight: inherit;
      line-height: 20px;

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #f5f5f5;

      &:not(.leader-row) {
        background-color: #fafafa;
      }

      &.active {
        background-color: #00ffc4;
      }
    }
  }

  tr {
    > *:first-child {
      padding-left: 20px;
    }
    > *:last-child {
      padding-right: 20px;
    }
  }

  td {
    padding: 14px 10px;
    line-height: 24px;
  }

  .th-rank {
    width: 80px;
  }

  .th-name {
    width: 100%;
  }

  .th-date {
    width: 120px;
  }

  .th-points {
    width: 150px;
    min-width: 150px;
  }
}

.nameCell {
  max-width: Min(200px,15ch);
  @include textOverflow;

  .name {
    font-weight: 600;
  }
}

.rank {
  @include circle(24px);
  @include flexCenter;
  font-weight: 600;

  &.gold {
    background-color: #f9dc93;
  }

  &.silver {
    background-color: #dae2eb;
  }

  &.bronze {
    background-color: #ffbaa4;
  }
}

.total-points {
  display: flex;
  justify-content: flex-end;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 10px 8px 20px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include gap(20px, right);

  .leftButton {
    transform: rotate(180deg);
  }

  .pagination-pages {
    display: flex;
    align-items: center;
    @include gap(10px, right);

    .pageNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      font-weight: 600;
    }

    .pagesCount {
      color: rgba(#202020, 0.4);
    }
  }
}

.pagination-nav {
  @include gap(8px, right);

  button {
    padding: 8px;
    border: none;

    &:disabled {
      --btn-text-color: #A0A0A0;
    }
  }
}
