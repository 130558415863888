@use 'utils' as *;

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include flex-gap(60px);
  width: 100%;
}

.header {
  flex: 400px 0 1;
  @include gap(24px);
}

.title {
  max-width: 380px;
}

.subheadings {
  @include gap(16px);
}

.content {
  flex: 700px 1 1;
}
