@use 'utils' as *;

.activities {
  @include gap(16px);
}

.activity {
  @include gap(16px);
  padding: 24px 40px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  background-color: #f6f6f6;
  border-radius: 24px;
  border: 2px solid transparent;

  &.active {
    background-color: rgba(#00ffc4, 0.04);
    border-color: #00ffc4;
  }

  &.completed {
    background-color: transparent;
    border-color: #cfcfcf;
  }
}

.header {
  margin: 0 -15px;
  @include gap(8px);
}

.title {
  max-width: 400px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.rewards {
  @include gap(6px, right);
  @include flexCenter;
  font-weight: 500;
}

.rewards-icon {
  color: #00b387;
}

.rewards-heading {
  color: #00b387;
  font-weight: 500;
}

.rewards-heading-coins {
  color: #000;
}

.rewards-coins {
  @include gap(4px, right);
}

.actions {
  display: flex;
  @include gap(32px, right);

  > * {
    flex: 100% 1 1;
    padding-right: 12px;
    padding-left: 12px;
  }
}
