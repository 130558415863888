@use 'utils' as *;

.card {
  @include gap(24px);
  @include loaderWrapper;
  padding: 48px 40px;
  background-color: #f6f6f6;
  border-radius: 24px;
}

.heading,
.text {
  text-align: center;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.input {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;

  .link {
    @include textOverflow;
    white-space: nowrap;
  }

  .button {
    font-weight: 700;
    color: #0ed3a3;
  }
}

.text {
  font-size: 18px;
  line-height: 25px;

  .count {
    font-weight: 700;
  }
}
