@use 'utils' as *;

.loader {
  @include absoluteCenter;
  font-size: 21px;

  &::after {
    content: '.';
    width: 1em;
    display: inline-block;
    text-align: left;
    animation: ellipsis 1.25s infinite;
  }
}

@keyframes ellipsis {
  33% {
    content: '..';
  }

  66% {
    content: '...';
  }
}
