.root {
  position: relative;
  display: flex;
  overflow: hidden;
}

.viewport {
  flex: 1;
  //grow h-full w-full rounded-[inherit]
}


.scrollbar {
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  touch-action: none;
  user-select: none;
  transition-property: background-color;
  transition-timing-function: ease;
  transition-duration: 300ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.vertical {
    height: 100%;
    width: 1px;
    margin-right: 1px;
  }

  &.horizontal {
    height: 1px;
    margin-top: 1px;
    width: 100%;
  }
}

.thumb {
  position: relative;
  flex: 1;
  margin: 0 -1px;
  border-radius: 2px;
  transition-property: background-color;
  transition-timing-function: ease;
  transition-duration: 300ms;
  background-color: rgba(0, 0, 0, 0.7);

  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
}
