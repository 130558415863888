@use '../../../utils/index' as *;

.wrapper {
  display: flex;
  @include gap(12px,right);
  align-items: center;
  width: 100%;
  padding: 7px 20px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  transition: border-color 250ms ease;

  &:not([data-disabled]):focus-within {
    border-color: #00ffc4;
  }

  &[aria-invalid="true"] {
    border-color: #fc174d;
  }

  input {
    width: 100%;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    outline: none;

    &::placeholder {
      color: #010500;
    }

    &[type='number'] {
      appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}
