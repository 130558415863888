@use 'utils' as *;

.loader {
  @include absoluteCenter;
  display: flex;
  align-items: center;

  &::before,
  &::after {
    content: '';
    background: url(/assets/images/icons/gear.svg) center/cover no-repeat;
    animation: spin linear infinite;
  }

  &::before {
    @include square(50px);
    animation-duration: 4s;
  }

  &::after {
    @include square(25px);
    margin-left: -6px;
    animation-duration: 2s;
    animation-direction: reverse;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
