@use 'utils' as *;

.container {
  @include gap(24px);
  max-width: 590px;
  margin: 0 auto;

  a {
    position: relative;
    display: inline-block;
    color: #00ffc4;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      &::after {
        background-color: transparent;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 2px;
      height: 2px;
      background-color: currentColor;
      transition: background-color 150ms ease-in;
    }
  }
}
