@use 'utils' as *;

.content {
  flex: 1 1 100%;
  max-height: 430px;
}

.list {
  display: grid;
  grid-gap: 15px;
  width: 100%;
  padding-right: 20px;

  .walletButton {
    width: 100%;
    padding-right: 20px;
    padding-left: 24px;
    justify-content: space-between;

    &:hover {
      & .statusAccounts {
        color: #00b387;
      }
    }
  }
}

.status {
  display: block;
  text-align: right;

  &Text {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #a2a2a2;
  }

  &Accounts {
    display: block;
    font-weight: 700;
    font-size: 11px;
    line-height: 10px;
    letter-spacing: 0.02em;
    color: #02f8bf;
    transition: color 250ms ease;
  }
}

.account {
  @include gap(10px, right);
  display: flex;
  align-items: center;

  &Button {
    @include gap(12px, right);
    justify-content: center;
    display: inline-grid;
    grid-template-columns: auto auto;
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    font-weight: 600;
    font-size: 16px;

    span {
      display: block;
      max-width: 22ch;
      white-space: nowrap;
      @include textOverflow;
    }
  }

  &Icon {
    pointer-events: none;
  }
}

.footer {
  margin-top: 22px;
  display: flex;
  justify-content: space-between;

  .walletButton {
    @include gap(8px, right);
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.changeText {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #00b387;
}

.textButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: opacity 300ms ease;
  @include gap(10px, right);

  &:hover {
    opacity: 50%;
  }
}
