@use 'utils' as *;

.container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  align-items: center;
  gap: 45px;
}

.header {
  @include gap(24px);
}

.subheadings {
  @include gap(16px);
}
