@use 'assets/styles';
@use '@gear-js/ui/variables' as *;
@use 'utils' as *;

#root {
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 20px;
}

main {
  @include loaderWrapper;
  display: flex;
  flex: 1;
  padding: 32px 0;
}
