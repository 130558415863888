@use 'utils' as *;

.container {
  @include gap(40px);
  max-width: 590px;
  margin: 0 auto;
}

.subheadings {
  @include gap(24px);
}
