@use 'utils' as *;

.wrapper {
  @include gap(8px);
  display: inline-block;
  text-align: center;
}

.claims {
  font-size: 12px;
}
