@use 'utils' as *;

.modal {
  max-width: 580px;
}

.container {
  @include gap(24px);
}

.header {
  @include gap(16px);
  text-align: center;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
